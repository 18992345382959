import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ScrollToTop from './component/scrollToTop/ScrollToTop';
import 'bootstrap/dist/css/bootstrap.min.css';


// Home Pages Import
import DigitalAgency from './pages/DigitalAgency';

// Blog Import


// Service 
import ServiceOne from './pages/ServiceOne';
import ServiceTwo from './pages/ServiceTwo';
import ServiceDetails from './pages/ServiceDetails';



// Pages 

import AboutUs from './pages/AboutUs';
import OurOffice from './pages/OurOffice';
import OurClients from './pages/OurClients';
import Team from './pages/Team';
import TeamDetails from './pages/TeamDetails';
import CaseStudy from './pages/CaseStudy';
import CaseDetails from './pages/CaseStudyDetails';
import Testimonials from './pages/Testimonials';
import PricingTable from './pages/PricingTable';
import Typography from './pages/Typography';
import Contact from './pages/Contact';
import ErrorPage from './pages/404';
import ComingSoon from './pages/ComingSoon';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';

// Css Import
import './assets/scss/app.scss';


const App = () => {
	return (
		<Router>
			<ScrollToTop>
				<Routes>
					<Route path={process.env.PUBLIC_URL + "/"} element={<DigitalAgency />} />

					{/* Service */}
					<Route path={process.env.PUBLIC_URL + "/service-one/"} element={<ServiceOne />} />
					<Route path={process.env.PUBLIC_URL + "/service-two/"} element={<ServiceTwo />} />
					<Route path={process.env.PUBLIC_URL + "/service-details/:slug"} element={<ServiceDetails />} />

					{/* Pages  */}
					<Route path={process.env.PUBLIC_URL + "/about-us"} element={<AboutUs />} />
					<Route path={process.env.PUBLIC_URL + "/our-office"} element={<OurOffice />} />
					<Route path={process.env.PUBLIC_URL + "/our-clients"} element={<OurClients />} />
					<Route path={process.env.PUBLIC_URL + "/team"} element={<Team />} />
					<Route path={process.env.PUBLIC_URL + "/team-details/:slug"} element={<TeamDetails />} />
					<Route path={process.env.PUBLIC_URL + "/case-study"} element={<CaseStudy />} />
					<Route path={process.env.PUBLIC_URL + "/case-details/:slug"} element={<CaseDetails />} />
					<Route path={process.env.PUBLIC_URL + "/testimonials"} element={<Testimonials />} />
					<Route path={process.env.PUBLIC_URL + "/pricing-table"} element={<PricingTable />} />
					<Route path={process.env.PUBLIC_URL + "/typography"} element={<Typography />} />
					<Route path={process.env.PUBLIC_URL + "/careers"} element={<Contact />} />
					<Route path={process.env.PUBLIC_URL + "/404"} element={<ErrorPage />} />
					<Route path={process.env.PUBLIC_URL + "/coming-soon"} element={<ComingSoon />} />
					<Route path={process.env.PUBLIC_URL + "/privacy-policy"} element={<PrivacyPolicy />} />
					<Route path={process.env.PUBLIC_URL + "/terms-use"} element={<TermsOfUse />} />

				</Routes>
			</ScrollToTop>
		</Router>
	)
}

export default App;
