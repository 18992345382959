import React from 'react';
import FormOne from '../contact/FormOne';
import CaseStudyProp from '../../component/casestudy/CaseStudyProp';

const AboutOne = () => {
    return (
        <section className="section section-padding-equal bg-color-light" id='about'>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="about-us">
                            <div className="section-heading heading-left mb-0">
                                <span className="subtitle">About Us</span>
                                <h2 className="title mb--40">Your Trusted Experts in Medical Transportation.</h2>
                                <p>At PRECISION MEDICAL CAB, we specialize in providing safe, reliable, and compassionate non-emergency medical transportation. Whether you need transportation to medical appointments, rehabilitation centers, or any other healthcare facilities, our professional drivers are committed to ensuring a comfortable and timely journey. Our comprehensive services are tailored to meet the unique needs of our clients, offering door-to-door assistance and specialized care for those with mobility challenges. Trust us to be your dependable partner in getting you where you need to go with care and precision.</p>
                                {/*<p>Nulla pharetra hendrerit mi quis dapibus. Quisque luctus, tortor a venenatis fermentum, est lacus feugiat nisl, id pharetra odio enim eget libero. </p>*/}
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-5 col-lg-6 offset-xl-1">
                        <div className="contact-form-box">
                            <h3 className="title">Get a free Keystroke quote now</h3>
                            <FormOne />
                        </div>
                    </div>
                </div>
            </div>
            <div className="pt--250 pt_lg--200 pt_md--100 pt_sm--80 case-study-page-area">
                <div className="container">
                    <CaseStudyProp />
                </div>
            </div>
            <ul className="shape-group-6 list-unstyled">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-7.png"} alt="Bubble" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="line" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/line-5.png"} alt="line" /></li>
            </ul>
        </section>
    )
}

export default AboutOne;