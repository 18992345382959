import React from 'react';
import { Link } from 'react-router-dom';
import { FaAngleDown } from "react-icons/fa";


const Nav = () => {
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.addEventListener('click', function (e) {
            e.preventDefault();
    
            document.querySelector(this.getAttribute('href')).scrollIntoView({
                behavior: 'smooth'
            });
        });
    });
    
    return (
        <nav className="mainmenu-nav">
            <ul className="mainmenu">
                <li><a href="/#hero">Home</a></li>
                <li><a href="/#services">Services</a></li>
                <li><a href="/#about">About</a></li>
                {/* <li><a href="/#testimonial">Testimonial</a></li> */}
                <li><a href="/#careers">Careers</a></li>
                {/* <li><Link to={process.env.PUBLIC_URL + "/careers"}>Contact</Link></li> */}
            </ul>
        </nav>
    )
}

export default Nav;