import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import AboutOne from '../component/about/AboutOne';
import BannerOne from '../component/banner/BannerOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ServicePropOne from '../component/service/ServicePropOne';
import TestimonialOne from '../component/testimonial/TestimonialOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import HowItWorks from '../component/about/AboutThree';

const DigitalAgency = () => {

    return (
        <>
            <SEO title="Digital Agency" />
            <ColorSwitcher />
            <main className="main-wrapper" style={{ scrollBehavior: "smooth" }}>
                <HeaderOne />
                <BannerOne />
                <div className="section section-padding-2 bg-color-dark" id='services'>
                    <div className="container">
                        <SectionTitle
                            subtitle="What We Can Do For You"
                            title="Our Comprehensive Transportation Solutions"
                            description="At PRECISION MEDICAL CAB, we provide safe, reliable, and compassionate non-emergency medical transportation."
                            textAlignment="heading-light-left"
                            textColor=""
                        />
                        <div className='row'>
                            <ServicePropOne colSize="col-xl-6 col-md-6" serviceStyle="" itemShow="3" />
                        </div>
                    </div>
                    <ul className="list-unstyled shape-group-10">
                        <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/line-9.png"} alt="Circle" /></li>
                        <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-42.png"} alt="Circle" /></li>
                        <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-43.png"} alt="Circle" /></li>
                    </ul>
                </div>
                <AboutOne />
                <HowItWorks />
                {/* <TestimonialOne /> */}
                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default DigitalAgency;

