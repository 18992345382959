import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import Alert from 'react-bootstrap/Alert';
// import { firebaseApp } from '../../Firebase/firebase';
import { getFirestore } from 'firebase/firestore';
import { initializeApp } from "firebase/app";
import { addDoc, collection } from 'firebase/firestore';


const firebaseConfig = {
    apiKey: "AIzaSyCZUHUYcNo-dDD9sN81EFN3dn1ff1hVjj8",
    authDomain: "precision-medical-cab.firebaseapp.com",
    projectId: "precision-medical-cab",
    storageBucket: "precision-medical-cab.appspot.com",
    messagingSenderId: "660656355238",
    appId: "1:660656355238:web:985da7275d703c5ae7c167"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

const Result = () => {
    return (
        <Alert variant="success" className="success-msg">
            Your Message has been successfully sent.
        </Alert>
    )
}

const FormOne = () => {

    const form = useRef();

    const [result, showresult] = useState(false);

    const sendEmail = async (e) => {
        e.preventDefault();

        const db = getFirestore(firebaseApp);

        try {
            const formData = {
                name: form.current['contact-name'].value,
                email: form.current['contact-email'].value,
                phone: form.current['contact-phone'].value,
                message: form.current['contact-message'].value,
                timestamp: new Date()
            };

            await addDoc(collection(db, "contactForms"), formData);
            console.log("Form submitted successfully");
            form.current.reset();
            showresult(true);
        } catch (error) {
            console.error("Error submitting form: ", error);
        }
    };


    setTimeout(() => {
        showresult(false);
    }, 5000);


    return (
        <form ref={form} onSubmit={sendEmail} className="axil-contact-form">
            <div className="form-group">
                <label>Name</label>
                <input type="text" className="form-control" name="contact-name" placeholder="John Smith" required />
            </div>
            <div className="form-group">
                <label>Email</label>
                <input type="email" className="form-control" name="contact-email" placeholder="example@mail.com" required />
            </div>
            <div className="form-group mb--40">
                <label>Phone</label>
                <input type="tel" className="form-control" name="contact-phone" placeholder="+123456789" required />
            </div>
            <div className="form-group mb--40">
                <label>How can we help you?</label>
                <input type="message" className="form-control" name="contact-message" placeholder="Your message here..." required />
            </div>
            <div className="form-group">
                <button type="submit" className="axil-btn btn-fill-primary btn-fluid btn-primary" name="submit-btn">Get Free Quote</button>
            </div>
            <div className="form-group">
                {result ? <Result /> : null}
            </div>

        </form>
    )
}

export default FormOne;