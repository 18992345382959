import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';

const Datas = [
    {
        id: 1,
        title: "Initial Contact",
        para: " Reach out to our service via phone or online contact us form to schedule your transport needs."
    },
    {
        id: 2,
        title: "Assessment and Planning",
        para: "Our team evaluates the patient's specific requirements, including any medical equipment needed, and plans the most suitable transportation method."
    },
    {
        id: 3,
        title: "Pick-up and Transit",
        para: "On the scheduled day, our trained staff arrives at the specified location, assists the patient into the vehicle, and ensures a safe, comfortable journey to the medical facility."
    },
    {
        id: 4,
        title: "Return Trip Coordination",
        para: "After the medical appointment or hospital stay, we arrange the return trip, bringing the patient safely back home with any necessary care or equipment support."
    },
]




const AboutThree = () => {
    return (
        <div className="section section-padding bg-color-dark pb--80 pb_lg--40 pb_md--20" id='how-it-works'>
            <div className="container">
                <SectionTitle
                    subtitle="Better understand!"
                    title="How it works?"
                    description="Our medical transport service is designed to be simple and efficient. From initial contact to the return trip, we ensure a seamless experience for our patients. Here's a step-by-step guide to our process."
                    textAlignment="heading-left heading-light-left mb--100"
                    textColor=""
                />

                <div className="row">
                    {Datas.map((data) => (
                        <div className="col-lg-4" key={data.id}>
                            <div className="about-quality">
                                <h3 className="sl-number">{data.id}</h3>
                                <h5 className="title">{data.title}</h5>
                                <p>{data.para}</p>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
            <ul className="list-unstyled shape-group-10">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-1.png"} alt="Circle" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-3.png"} alt="Circle" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-5.png"} alt="Circle" /></li>
            </ul>
        </div>
    )
}

export default AboutThree;